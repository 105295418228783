/* eslint-disable react/jsx-no-bind */
import classNames from "classnames";
import Badge from "app/components/shared/Badge";
import { NavLink, NavLinkProps } from "react-router-dom";
import { useBuild } from "app/components/build/Show/lib/BuildContext";
import { BuildView } from "..";
import { urlForView } from "../lib/urlForView";
import useQueryParams from "../lib/useQueryParams";
import { useMemo } from "react";
import FailedTestExecutionsIndicator from "app/components/job/Job/FailedTestExecutionsIndicator";

const BuildNavLink = (props: NavLinkProps) => {
  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        classNames(
          "px-2 text-sm",
          "btn btn-tab text-current focus:text-current transition-colors relative",
          {
            "btn-tab--active": isActive,
          },
        )
      }
    />
  );
};

function BuildContentNavigation() {
  const { build } = useBuild();
  const params = useQueryParams();

  const navItems = useMemo(() => {
    const items: { to: string; label: string; indicator?: React.ReactNode }[] = [
      {
        to: urlForView(build.path, BuildView.Summary, params),
        label: "Summary",
      },
      { to: urlForView(build.path, BuildView.Steps, params), label: "Steps" },
    ];

    if (
      build.hasTestAnalytics &&
      Features.TestEngineBuildTabUplift &&
      !Features.TestEngineDisableBuildPageTestTab
    ) {
      items.push({
        to: urlForView(build.path, BuildView.Tests, params),
        label: "Tests",
        indicator: (
          <FailedTestExecutionsIndicator build={build} translateX="1px" translateY="8px" />
        ),
      });
    }

    return items;
  }, [build, params]);

  if (!Features.BuildSidebar) {
    return <ClassicNavigation />;
  }

  return (
    <nav data-testid="BuildContentNavigation" className="flex">
      {navItems.map(({ label, to, indicator }) => (
        <BuildNavLink key={to} to={to}>
          {indicator}
          {label}
        </BuildNavLink>
      ))}
    </nav>
  );
}

/**
 * Navigation for users without BuildSidebar feature
 */
function ClassicNavigation() {
  const { build, store } = useBuild();

  const listHref = build.path;
  const testsHref = urlForView(build.path, BuildView.Tests);
  const canvasHref = urlForView(build.path, BuildView.Canvas);
  const waterfallHref = urlForView(build.path, BuildView.Waterfall);
  const hasTestEngine = build.hasTestAnalytics;
  const showBuildTests =
    Features.TestEngineBuildTabUplift && !Features.TestEngineDisableBuildPageTestTab;

  return (
    <nav className="Tablist flex border-b border-gray mb-[-1px]" data-testid="buildViewTabsNav">
      <NavLink
        to={listHref}
        end={true}
        className={({ isActive }) =>
          classNames("btn btn-tab text-current focus:text-current", {
            "btn-tab--active": isActive,
          })
        }
      >
        <span>Jobs</span>
      </NavLink>

      <NavLink
        to={canvasHref}
        className={({ isActive }) =>
          classNames("btn btn-tab text-current focus:text-current", {
            "btn-tab--active": isActive,
          })
        }
      >
        <span>Canvas</span>
      </NavLink>

      <NavLink
        to={waterfallHref}
        className={({ isActive }) =>
          classNames("btn btn-tab text-current focus:text-current", {
            "btn-tab--active": isActive,
          })
        }
      >
        <span>Waterfall</span>
        {!store.waterfallAvailable && (
          <Badge outline={false} className="white bg-purple">
            Upgrade
          </Badge>
        )}
      </NavLink>

      {hasTestEngine && showBuildTests && (
        <NavLink
          to={testsHref}
          className={({ isActive }) =>
            classNames("btn btn-tab text-current focus:text-current relative", {
              "btn-tab--active": isActive,
            })
          }
        >
          <FailedTestExecutionsIndicator build={build} />
          <span>Tests</span>
        </NavLink>
      )}
    </nav>
  );
}

export default BuildContentNavigation;
